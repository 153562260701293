import React from 'react'
import PropTypes from 'prop-types'
import {Row, Col} from "react-bootstrap"
import ReactJson from 'react-json-view'

class LogItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            parsed: {
                input: false,
                output: false
            }
        }
    }

    onParse(type) {
        const parsed = this.state.parsed
        parsed[type] = true
        this.setState({parsed: parsed});
    }

    render() {
        const parsed = this.state.parsed
        const data = this.props.data
        const browser = data.browser ? JSON.parse(data.browser) : {}

        return (
            <React.Fragment>
                <h3>{data.request} <small>({data.message})</small></h3>
                <h5>{data.date}</h5>
                <div>source: {browser.name}, {browser.version}, {browser.os}, {data.ip}</div>
                <hr/>
                <div>
                    <Row>
                        <Col md={6}>
                            <h3>input:</h3>
                            {
                                data.input ?
                                    parsed.input ?
                                        <ReactJson
                                            src={JSON.parse(data.input)}
                                            theme="rjv-default"
                                            iconStyle="square"
                                            displayObjectSize={false}
                                            displayDataTypes={false}
                                        />
                                        :
                                        <button className="btn btn-theme" onClick={() => this.onParse('input')}>zobrazit
                                            data</button>
                                    :
                                    <p>žádná data</p>
                            }
                        </Col>
                        <Col md={6}>
                            <h3>output:</h3>
                            {
                                data.data ?
                                    parsed.output ?
                                        <ReactJson
                                            src={JSON.parse(data.data)}
                                            theme="rjv-default"
                                            iconStyle="square"
                                            displayObjectSize={false}
                                            displayDataTypes={false}
                                        />
                                        :
                                        <button className="btn btn-theme" onClick={() => this.onParse('output')}>zobrazit
                                            data</button>
                                    :
                                    <p>žádná data</p>
                            }
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

LogItem.propTypes = {
    data: PropTypes.object.isRequired
}

export default LogItem
import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import Header from "../common/Header"
import Navigation from "../common/Navigation"
import Spinner from "../common/Spinner"
import Empty from "../common/Empty"
import {Row, Col} from "react-bootstrap"
import {setActiveMenuItem} from '../../actions/NavigationActions'
import {ToastDanger} from 'react-toastr-basic'
import {ToastSuccess} from 'react-toastr-basic'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ReactPaginate from 'react-paginate'

import LogItem from '../common/LogItem'
import BoxAddSimple from "../common/BoxAddSimple"

import {homepage} from '../../constants/constants'

import {
    getLog,
} from '../../actions/LogActions';

class LogPage extends React.Component {
    constructor(props, context) {
        super(props);

        if (!props.auth.user.master_user) {
            context.router.history.push(homepage)
        }

        this.itemsPerPage = 100

        this.state = {
            errors: [],
            counter: 0,
            pagination: {
                offset: 0,
                limit: this.itemsPerPage
            },
            filter: {
                search: '',
                date_from: '',
                date_to: ''
            }
        }
        props.actions.setActiveMenuItem('log');
        props.actions.getLog({pagination: this.state.pagination});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.counter != prevState.counter) {
            const {errors} = this.state;
            Object.keys(errors).map(key =>
                ToastDanger(errors[key])
            );
        }
        if (this.props != prevProps) {
            const {errors, message} = this.props.log;
            if (errors) {
                errors.map((item) =>
                    ToastDanger(item)
                );
            }
            if (message && message != prevProps.log.message) {
                ToastSuccess(message);
            }
        }
    }

    onClickReset() {
        this.resetState();
    }

    resetState() {
        this.setState({
            pagination: {
                offset: 0,
                limit: this.itemsPerPage
            },
            filter: {
                search: '',
                date_from: '',
                date_to: ''
            },
            errors: [],
            counter: 0
        });
    }

    onInputChange(e) {
        const filter = this.state.filter
        filter[e.target.name] = e.target.value
        this.setState({
            filter: filter,
            pagination: {
                offset: 0,
                limit: this.itemsPerPage
            }
        });
    }

    onfilter() {
        this.props.actions.getLog({filter: this.state.filter, pagination: this.state.pagination});
    }

    onPaginationClick(data) {
        let offset = Math.ceil(data.selected * this.itemsPerPage);

        const pagination = this.state.pagination
        pagination.offset = offset

        this.setState({pagination: pagination}, () => {
            this.props.actions.getLog({filter: this.state.filter, pagination: this.state.pagination})
        })
    }

    render() {
        const {log, search} = this.props;

        const items = log.items.items ?
            search ?
                log.items.items.filter(item => item.request.toLocaleLowerCase().includes(search.toLowerCase()))
                :
                log.items.items
            : [];

        const boxes = items.length ? items.map(item => {
            return (
                <Col md={12} key={item.id}>
                    <div className="box">
                        <div className="box-content">
                            <LogItem data={item}/>
                        </div>
                    </div>
                </Col>
            )
        }) : log.isFetching ? null : <Empty/>;

        const total = log.items.total || 0
        const pageCount = Math.ceil(total / this.itemsPerPage)

        return (
            <div>
                <Navigation/>
                <div className="main-page">
                    <Header/>
                    <div className="main-content" id="top">
                        <Spinner visible={log.isFetching}/>
                        <Row>
                            <BoxAddSimple
                                active={false}
                                fields={[
                                    {
                                        type: 'text',
                                        label: 'request nebo message',
                                        value: this.state.search,
                                        name: 'search',
                                        onChange: (e) => this.onInputChange(e)
                                    },
                                    {
                                        type: 'datetime-local',
                                        label: 'datum od',
                                        value: this.state.date_from,
                                        name: 'date_from',
                                        onChange: (e) => this.onInputChange(e)
                                    },
                                    {
                                        type: 'datetime-local',
                                        label: 'datum do',
                                        value: this.state.date_to,
                                        name: 'date_to',
                                        onChange: (e) => this.onInputChange(e)
                                    }
                                ]}
                                button={{label: 'filtrovat', onClick: () => this.onfilter()}}
                                reset={() => this.onClickReset()}
                            />
                        </Row>
                        <Row>
                            {boxes}
                        </Row>
                        {
                            pageCount > 1 &&
                            <Row>
                                <ReactPaginate
                                    previousLabel={<FontAwesomeIcon icon='chevron-left'/>}
                                    nextLabel={<FontAwesomeIcon icon='chevron-right'/>}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount || 1}
                                    forcePage={(this.state.pagination.offset / this.itemsPerPage)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={(data) => this.onPaginationClick(data)}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                />
                            </Row>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

LogPage.propTypes = {
    auth: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
}

LogPage.contextTypes = {
    router: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        log: state.log,
        search: state.search
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getLog,
            setActiveMenuItem
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogPage);